import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

import { ParallaxProvider } from 'react-scroll-parallax';

import EventThumb from "../components/event_thumb";
import HomeChiSiamo from "../components/home_chisiamo";
import WorkshopSlides from "../components/home_slides";



const IndexPage = ({data, location, path}) => {
  const eventi = data.allContentfulEvento.nodes
  

  return (
    <Layout location={location} title=''>
      
      <ParallaxProvider>
      
      <WorkshopSlides data={data} />

      <section className="bg-background pb-40 relative z-20">
        <h2 className="text-white text-center p-0 relative -top-5 tracking-wider">Prossimi Eventi</h2>
        <div className="container max-w-screen-xl grid md:grid-cols-1 lg:grid-cols-3 gap-16">
        {eventi.map(evento => {
          return (
            <a href={"/" + evento.slug} itemProp="url">
              <EventThumb titolo={evento.titolo} dataInizio={evento.data} coverImage={evento.coverImage.gatsbyImageData} />
            </a>
          )
        })}
          
        </div>
        <div className="text-center py-20">
        <Link to="/eventi">
          <button>Scopri tutti gli eventi</button>
        </Link>
        </div>
      </section>

      <HomeChiSiamo />

      </ParallaxProvider>
      
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
    query {
      allContentfulEvento(
        sort: {fields: dataInizio, order: DESC}
        filter: {homepage: {eq: true}}
        ) {
        nodes {
          coverImage {
            gatsbyImageData(
              quality: 80
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              aspectRatio: 1
            )
          }
          titolo
          slug
          prezzo
          data
          dataInizio(formatString: "DD-MM-YY")
        }
      }
      allContentfulHomeSlide {
        nodes {
          coverImage {
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
                aspectRatio: 1
              )
          }
          title
          titolo02
          titolo03
          testoSuFoto
          link
        }
      }
    }
  `