import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";

const EventThumb = ({titolo, dataInizio, coverImage}) => {


  return (
    <div className="grid grid-cols-1 mt-20">
      <GatsbyImage
        itemProp="image"
        image={coverImage}
        alt={titolo}
        className="mt-5 mx-5 transform duration-300 hover:scale-99 shadow-lg"
        />
        <div className="border-b-2 border-white -mt-2">
        <div className="text-2xl text-white font-sans font-bold text-center pt-10 pb-5">{dataInizio}</div>
        </div>
        <h4 className="text-white text-center px-10">{titolo}</h4>
    </div>
  )
}

export default EventThumb