import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal";
import { Parallax } from 'react-scroll-parallax';

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper-bundle.css'
import { Link } from "gatsby";



const WorkshopSlides = ({data}) => {


    
    
    // install Swiper modules
    SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

    return (
        <div className="z-10">
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                navigation={{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',}}
                Lazy
                A11y
            >

            <div className="swiper-button-prev p-10"></div>
            <div className="swiper-button-next p-10"></div>
            
            {data.allContentfulHomeSlide.nodes.map(slide => {
                return (
                    
                    <SwiperSlide>
                    <div className="pb-52 md:pb-36 h-full bg-red_secondary">
                        <Fade>
                        <Link to={slide.link}>
                        <div className="max-w-screen-2xl mx-auto px-5 grid md:grid-cols-2 pt-20">
                            <Fade>
                                <GatsbyImage
                                    image={slide.coverImage.gatsbyImageData}
                                    alt={slide.title}
                                    className="m-10 md:m-20 transform duration-300 hover:scale-99"
                                />
                            </Fade>
                            <Parallax y={[30, 0]} tagOuter="div">
                            {slide.testoSuFoto === true ?
                            <div className="md:-ml-32 -mt-10 md:mt-20 z-10">
                                <h1 className="text-10vw md:text-5vw md:leading-5vw text-center md:text-left pb-0">{slide.title}</h1>
                                <h3 className="text-5vw md:text-2vw leading-5vw md:leading-2vw text-center md:text-left text-white md:ml-20 py-2">{slide.titolo02}</h3>
                                <h3 className="text-5vw md:text-2vw leading-5vw md:leading-2vw text-center md:text-left md:ml-20 py-0">{slide.titolo03}</h3>
                            </div>
                            :
                            <div className="-mt-10 md:mt-20 z-10">
                                <h1 className="text-10vw md:text-5vw md:leading-5vw text-center md:text-left pb-0">{slide.title}</h1>
                                <h3 className="text-5vw md:text-2vw leading-5vw md:leading-2vw text-center md:text-left text-white py-2">{slide.titolo02}</h3>
                                <h3 className="text-5vw md:text-2vw leading-5vw md:leading-2vw text-center md:text-left py-0">{slide.titolo03}</h3>
                            </div>
                            }
                            </Parallax>
                        </div>
                        </Link>
                        </Fade>
                        </div>
                    </SwiperSlide>
                    
                )
            })}
        </Swiper>
        </div>
    )
}


export default WorkshopSlides